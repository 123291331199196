<template>
  <div>
    <div class="md">
      <img src="../../../../static/private-b/wap.jpg" alt="活动页" style="width: 100%;" />
      <a :href="'https://sem.rlcpluspro.com/subject/download?channel='+v" class="mda"></a>
    </div>

    <div class="lg">
      <img src="../../../../static/private-b/web.jpg" alt="活动页" style="width: 100%;" />
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
       v:this.$route.query.v||9912
    }
   
  }
}
</script>

<style scoped>
/*  Small devices (phones) */
@media only screen and (max-width: 768px) {
  .md {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
  .mda{
    width: 148px;
    height: 34px;
    position: absolute;
    top: 196px;
    right: 65px;
  }
  .lg {
    display: none;
  }
}

/* Large devices (pc) */
@media only screen and (min-width: 769px) {
  .md {
    display: none;
  }

  .lg {
    display: block;
  }
}
</style>
